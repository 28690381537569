<template>
  <div>
    <div class="all_container">
      <div class="top">
        <div class="left" @click="goback">
          <img src="@/assets/particulars_icons/back.png" alt="" />
          <span>{{ $t("other.backResult") }}</span>
        </div>
        <div class="right_box">
          <div class="center">
            <img src="@/assets/particulars_icons/share.png" alt="" />
            <span class="share_text">{{ $t("other.share") }}：</span>

            <div class="share_list" style="line-height: 5px">
              <el-dropdown trigger="click">
                <div class="el-dropdown-link share_item" @click="shareClick(1)">
                  <img src="@/assets/home_icons/share_wexin.png" />
                </div>
                <el-dropdown-menu slot="dropdown">
                  <div class="qr_code">
                    <div>
                      <vue-qr
                        :text="wechatShareUrl"
                        :size="148"
                        :logoSrc="logoSrc"
                      ></vue-qr>
                    </div>
                    <div class="scan_text">{{ $t("weChatScan") }}</div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div
              v-for="(item, index) in shareList"
              :key="index"
              class="share_list"
            >
              <img :src="item.img" alt="" @click="shareClick(item.id)" />
            </div>
          </div>
          <div class="right">
            <div class="right_box_one" @click="collectClick">
              <!-- <img src="@/assets/particulars_icons/like.png" alt="" > -->
              <img
                src="@/assets/home_icons/collect.png"
                v-if="information.is_collection == 0"
              />
              <img src="@/assets/home_icons/collected.png" v-else />
              <div>{{ $t("home.collect") }}</div>
              <!-- <div v-else>已收藏{{ is_like_status }}</div> -->
            </div>
            <div class="right_box_one" @click="toInformClick">
              <img src="@/assets/particulars_icons/jubao.png" alt="" />
              <div>{{ $t("home.report") }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="video">
        <video :src="information.video" class="video_video" controls></video>
        <!-- <img src="@/assets/particulars_icons/bofang.png" alt="" class="bofang"> -->
        <div class="video_title">{{ information.title }}</div>
        <div class="video_address">
          <div>${{ information.price }}</div>
          <div class="video_address_name">
            <img src="@/assets/particulars_icons/address_icon.png" alt="" />
            <div>
              {{ information.province_name }}-{{ information.addr_name }}-{{
                information.area_name
              }}
            </div>
          </div>
        </div>
        <!-- <video src=""></video> -->
      </div>

      <div class="video_text">
        <div class="top">
          <div class="left">
            <div
              class="left_box"
              v-for="(item, index) in information.data_arr"
              :key="index"
            >
              <img :src="item.icon_url" alt="" />
              <div class="name">
                <div class="title">
                  {{ lang == "zh" ? item.key_name : item.sign }}
                </div>
                <div class="num">{{ item.value }}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right_box" @click="contactClick(2)">
              <img src="@/assets/particulars_icons/iphone.png" alt="" />
              <div class="name">WhatsApp</div>
            </div>
            <div class="right_box" @click="contactClick(1)">
              <img src="@/assets/particulars_icons/info.png" alt="" />
              <div class="name">{{ $t("messageContact") }}</div>
            </div>
            <div class="right_box" @click="contactClick(4)">
              <img src="@/assets/particulars_icons/email.png" alt="" />
              <div class="name">{{ $t("emailContact") }}</div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="title">
              {{
                lang == "zh" ? information.row.name : information.row.bnm_name
              }}
            </div>
            <div class="money">${{ information.price }}</div>
          </div>
          <div class="right">
            <img
              :src="information.company_logo"
              alt=""
              style="width: 150px; height: 100px; margin-right: 56px"
            />
            <div class="see" style="margin-right: 56px">
              <img src="@/assets/particulars_icons/see.png" alt="" />
              <div>{{ information.look_count }}</div>
            </div>
            <div style="margin-right: 56px">
              {{ $t("home.releaseTime") }} {{ information.create_time }}
            </div>
            <div style="margin-right: 56px">ID:{{ information_id }}</div>
            <div class="see">
              <img src="@/assets/particulars_icons/address.png" alt="" />
              <div>
                {{ information.province_name }}-{{ information.addr_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 基本信息展示 -->
    <div class="infoShow">
      <div
        v-for="(item, index) in information.sku_data"
        :key="index"
        class="info_box"
      >
        <div class="title">{{
          lang == "zh" ? item.key_name : item.sign
        }}</div>
        <div class="name">{{ item.value }}</div>

        <!-- <div class="name">{{ lang == "zh" ? item.value:item.select_value }}</div> -->
      </div>
      <div class="border" v-if="information.sku_data.length > 8"></div>
    </div>

    <div class="photoShow">
      <div class="title">
        {{ $t("home.pictureDisplay") }}
      </div>
      <!-- <div class="photo_box" :style="'grid-template-rows:repeat(' + photoIndex + ', 320px)'">
            
                <div class="box_item1" v-if="information.thumb[0]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[0]" alt="">
                    </viewer>
                </div>
                <div class="box_item2" v-if="information.thumb[1]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[1]" alt="">
                    </viewer>
                </div>
                <div class="box_item3" v-if="information.thumb[2]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[2]" alt="">
                    </viewer>
                </div>
                <div class="box_item4" v-if="information.thumb[3]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[3]" alt="">
                    </viewer>
                </div>
                <div class="box_item5" v-if="information.thumb[4]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[4]" alt="">
                    </viewer>
                </div>
                <div class="box_item6" v-if="information.thumb[5]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[5]" alt="">
                    </viewer>
                </div>
                <div class="box_item7" v-if="information.thumb[6]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[6]" alt="">
                    </viewer>
                </div>
                <div class="box_item8" v-if="information.thumb[7]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[7]" alt="">
                    </viewer>
                </div>
                <div class="box_item9" v-if="information.thumb[8]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[8]" alt="">
                    </viewer>
                </div>
                <div class="box_item10" v-if="information.thumb[9]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[9]" alt="">
                    </viewer>
                </div>
                <div class="box_item11" v-if="information.thumb[10]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[10]" alt="">
                    </viewer>
                </div>
                <div class="box_item12" v-if="information.thumb[11]">
                    <viewer style="width:100%;height:100%;">
                        <img :src="information.thumb[11]" alt="">
                    </viewer>
                </div>

            </div> -->

      <!-- :style="'grid-template-rows:repeat(' + photoIndex + ', 320px)'" -->
      <div class="photo_box">
        <!-- <div :class="'box_item' + (index + 1)" v-for="(item, index) in information.thumb" :key="index"> -->
        <div
          class="box_item"
          v-for="(item, index) in information.thumb"
          :key="index"
        >
          <!-- <viewer style="width:100%;height:100%;">
                      <img :src="item" alt="">
                  </viewer> -->
          <viewer>
            <img :src="item" alt="" mode="widthFix" />
          </viewer>
        </div>
      </div>
    </div>

    <!-- 地图 -->
    <div
      class="address"
      style="width: 800px; height: 400px; margin-bottom: 24px"
      v-if="Object.keys(location).length != 0"
    >
      <mapComponent :isShow="true" :new_location="location"></mapComponent>
    </div>

    <div class="info">
      <div class="info_box">
        <div class="table">
          <div class="title">{{ $t("title.classify") }}</div>
          <div class="name">{{ information.cate_name }}</div>
        </div>
        <div class="table">
          <div class="title">{{ $t("other.release") }}</div>
          <div class="name">
            {{ information.province_name }}-{{ information.addr_name }}-{{
              information.area_name
            }}
          </div>
        </div>
        <div class="table">
          <div class="title">{{ $t("fullAddress") }}</div>
          <div class="name">{{ information.address }}</div>
        </div>
        <div class="table">
          <div class="title">{{ $t("contacts") }}</div>
          <div class="name">{{ information.name }}</div>
        </div>
        <div class="table">
          <div class="title">{{ $t("telephone") }}</div>
          <div class="name">{{ information.mobile }}</div>
        </div>
        <div class="table">
          <div class="title">{{ $t("mail") }}</div>
          <div class="name">{{ information.email }}</div>
        </div>
        <div class="table">
          <div class="title">{{ $t("wx") }}</div>
          <div class="name">{{ information.whatsapp }}</div>
        </div>
        <div class="table">
          <div class="title"></div>
          <div class="name"></div>
        </div>
        <div class="table">
          <div class="title"></div>
          <div class="name"></div>
        </div>
      </div>
    </div>

    <!-- 历史记录 -->
    <div class="release">
      <div
        class="release_box"
        v-for="(item, index) in history_list"
        :key="index"
        @click="goCommonInfo(item)"
      >
        <div class="release_box_top">
          <img :src="item.thumb[0]" alt="" />
        </div>
        <div class="release_box_bootom">
          <div class="title">{{ item.title }}</div>
          <div class="address">
            <div class="address_left">${{ item.price }}</div>
            <div class="address_right">
              <img
                src="@/assets/new_home_icons/address_icon.png"
                alt=""
                class="img"
              />
              <div class="name">{{ item.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 审核框 -->
    <div>
      <div class="checkStatus checkStatusColoring" v-if="check == 1">
        <div class="status">
          {{ $t("curState") }}：<span class="ing">{{ $t("my.checkIn") }}</span>
        </div>
      </div>

      <div class="checkStatus checkStatusColorWin" v-if="check == 2">
        <div class="status">
          {{ $t("curState") }}：<span class="win">{{
            $t("my.checkSuccess")
          }}</span>
        </div>
        <div class="btn_box">
          <el-button class="del_btn" @click="goEdit(information_id)">{{
            $t("my.edit")
          }}</el-button>
          <el-button
            class="release_btn"
            v-if="information.status == 1"
            @click="isMySee(information_id)"
            >{{ $t("onlySee") }}</el-button
          >
          <el-button
            class="release_btn"
            v-else
            @click="isMySee(information_id)"
            >{{ $t("allSee") }}</el-button
          >
        </div>
      </div>

      <div class="checkStatus checkStatusColorLose" v-if="check == 3">
        <div class="status">
          {{ $t("curState") }}：<span class="lose">{{
            $t("my.checkFailure")
          }}</span>
        </div>
        <div class="btn_box">
          <el-button class="del_btn" @click="dialogVisible_del = true">{{
            $t("delete")
          }}</el-button>
          <el-button class="release_btn" @click="goRelease(1)">{{
            $t("my.submitAgain")
          }}</el-button>
        </div>
      </div>

      <div class="checkStatus" v-if="check == 4">
        <div class="status">{{ $t("curState") }}：{{ $t("my.expired") }}</div>
        <div class="btn_box">
          <el-button class="del_btn" @click="dialogVisible_del = true">{{
            $t("delete")
          }}</el-button>
          <el-button class="release_btn" @click="goRelease(2)">{{
            $t("my.submitAgain")
          }}</el-button>
        </div>
      </div>
    </div>

    <!-- 举报 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="38%"
      :append-to-body="true"
    >
      <div slot="title">
        <div class="title_all">
          <!-- <div class="title_img"><img src="@/assets/login/flag.png"></div> -->
          <div>{{ $t("home.report") }}</div>
        </div>
      </div>
      <div class="dialog_content">
        <div class="inform_title">{{ $t("home.reportContent") }}</div>
        <div class="textarea" @click="textareaClick">
          <el-input
            type="textarea"
            :autosize="true"
            :placeholder="$t('home.provideMoreInformation')"
            v-model="content"
            maxlength="200"
            ref="textareaRef"
          >
          </el-input>
          <div class="count">{{ content.length }}/200</div>
        </div>
        <div class="inform_title">{{ $t("home.uploadPhoto") }}</div>
        <div class="all_imgs">
          <el-upload
            :action="uploadImgUrl"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="3"
            :before-upload="beforeUpload"
            :on-change="onChange"
            :multiple="true"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              {{ $t("home.atMost") }}3{{ $t("home.open") }}
            </div>
          </el-upload>
          <el-dialog :visible.sync="previewImgDialog" :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <div class="btn" @click="btnClick">{{ $t("home.submit") }}</div>
        <div class="tip">{{ $t("home.informTips") }}</div>
      </div>
    </el-dialog>

    <!-- 短信联系弹窗 -->
    <div class="lianxi_dialog">
      <el-dialog title="" :visible.sync="wechatVisible" width="30%">
        <div slot="title">
          <div class="title_all">
            <div>{{ $t("messageContact") }}</div>
          </div>
        </div>
        <div>
          <div class="wx">{{ $t("contactWXId") }}</div>
          <div class="wx_num">{{ user_info.wechat }}</div>
        </div>
        <button type="submit" class="copy_btn" @click="copyWx">
          {{ $t("copy") }}
        </button>
      </el-dialog>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="" :visible.sync="dialogVisible_del" width="30%">
      <div slot="title">
        <div class="title_all">
          <div>{{ $t("my.tips") }}</div>
        </div>
      </div>
      <span>{{ $t("querenDel") }}？</span>
      <span slot="footer" class="dialog-footer" style="margin-top: 20px">
        <el-button @click="dialogVisible_del = false">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="deleteRelease(information_id)">{{
          $t("confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { uploadImgUrl, product_host } from "@/common/utils/config.js";
import vueQr from "vue-qr";
import mapComponent from "@/components/map.vue";

export default {
  components: {
    vueQr,
    mapComponent,
  },
  created() {
    console.log("普通详情页");
    this.lang = localStorage.getItem("lang");
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userinfo) {
      this.user_id = userinfo.id;
    }
    // console.log(userinfo.id);

    this.information_id = this.$route.query.id;
    this.check = this.$route.query.check;
    console.log(this.$route.query.id, "传过来的id");
    this.getInformationDetail();
    this.getHistoryList();

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值

      if (e.key === "lang") {
        this.lang = e.newValue;

        console.log(this.lang);
      }
    });

    // this.information_id = this.$route.query.id
    // this.information_id = 11549
  },

  monted() {
    this.wechatShareUrl =
      "https://www.publichino.com/weChatShare?information_id=" +
      this.information_id;
  },

  data() {
    return {
      shareList: [
        // {
        //     id: 1,
        //     img: require('@/assets/particulars_icons/wetchat.png')
        // },
        // {
        //     id:2,
        //     img: require('@/assets/particulars_icons/pengyouquan.png')
        // },
        {
          id: 4,
          img: require("@/assets/particulars_icons/email1.png"),
        },
        {
          id: 3,
          img: require("@/assets/particulars_icons/iphone1.png"),
        },
      ],

      checkList: ["选中且禁用"],
      information_id: "", //详情id
      information: {}, //信息详情
      user_info: {}, // 用户详情

      shareHref: "", //分享的链接
      lang: "",
      dialogVisible: false,
      wechatVisible: false, //复制微信号
      previewImgDialog: false, //预览图片
      dialogImageUrl: "", //预览图片地址
      uploadImgUrl: uploadImgUrl,

      isFlag: false,

      video: "", //视频地址
      cover: "", //封面地址

      content: "", //举报内容
      img_list: [], //上传图片列表

      contacts: "", //联系人
      wechat: "", //微信号
      whatsApp: "", //whatsApp
      mobile: "", //手机号
      user_email: "", //邮箱号
      company_name: "", //公司名称

      detailUserInfor: "", //发布信息的用户信息

      wechatShareUrl: "",
      logoSrc:
        "https://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png",
      subject: "巴拿马中文广告平台-Publichino.com",
      zhInterest: "我对您的广告很有感兴趣，请您联系我！",
      spInterest:
        "Estoy muy interesado en su anuncio, por favor contacte conmigo!",
      userData: "",
      release_userId: "",
      user_id: "", //我的用户id

      check: "0", //审核状态
      dialogVisible_del: false, // 删除弹窗状态

      photoIndex: "",
      location: {},
      history_list: [],
    };
  },
  watch: {
    lang(newval) {
      console.log(newval, 123);

      if (newval) {
        console.log(newval);
        this.getInformationDetail();
      }
    },

    location(newval) {
      if (newval) {
        this.location = newval;
        console.log(this.location, "watch");
      }
    },

    $route: {
      handler: function (route) {
        console.log(route, 12345);
        if (route.query.id != this.information_id) {
          this.information_id = route.query.id;
          this.getInformationDetail();
          this.getComments();
          this.getHistoryList();
        }
      },
      immediate: true,
    },
  },

  methods: {
    goback() {
      this.$router.go(-1);
    },

    // 获取历史足迹
    getHistoryList() {
      this.$http
        .historyList({
          user_id: this.user_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.history_list = res.data;
            console.log(this.history_list, "this.history_list");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goCommonInfo(item) {
      if (item.url == 1 || 2) {
        this.$router.push({
          path: "/particulars",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else {
        this.$router.push({
          // 普通页
          path: "/details",
          query: {
            id: item.id,
          },
        });
      }
    },

    // 获取详情
    getInformationDetail() {
      this.$http
        .informationDetail({
          information_id: this.information_id,
          user_id: this.user_id,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            this.information = res.data;
            this.user_info = res.data.user_info;
            this.release_userId = res.data.user_id;
            this.photoIndex = Math.ceil(this.information.thumb.length / 4);
            console.log(this.photoIndex);
            if (
              Number(this.photoIndex) != 5 &&
              Number(this.information.thumb.length) != 1
            ) {
              this.photoIndex = this.photoIndex + 1;
            } else if (Number(this.information.thumb.length) == 1) {
              this.photoIndex = this.photoIndex;
            }
            console.log(this.photoIndex, "图片行数");

            this.location = {
              lat: Number(res.data.lat),
              lng: Number(res.data.lng),
            };
          }
        });
    },

    goRelease(type) {
      this.$router.push({
        path: "/release",
        query: {
          information_id: this.information_id,
          end_status: type,
        },
      });
    },

    // 分享
    shareClick(val) {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t("other.noLoginPleaseToLogin"));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin",
          });
        }, 1500);
        return;
      }

      switch (val) {
        case 1:
          break;
        case 2:
          break;
        case 3:
          let whatsAppShareUrl =
            "https://api.whatsapp.com/send?text=" + this.shareHref;
          window.open(whatsAppShareUrl);
          break;
        case 4:
          let emailShareUrl =
            "mailto:?body=" + this.shareHref + "&subject=" + this.subject;
          window.location.href = emailShareUrl;
          break;
      }
    },

    // 联系发布人
    contactClick(val) {
      switch (val) {
        case 1:
          this.wechatVisible = true;
          break;
        case 2:
          let whatsApp = "+" + this.whatsApp;
          let contactHref =
            this.zhInterest + "\n" + this.spInterest + "\n" + this.shareHref;
          let whatsAppContactUrl =
            "https://api.whatsapp.com/send?phone=" +
            whatsApp +
            "&text=" +
            encodeURIComponent(contactHref);

          window.open(whatsAppContactUrl);
          break;
        case 3:
          break;
        case 4:
          let body =
            this.zhInterest +
            "%0A" +
            this.spInterest +
            "%0A" +
            this.$t("advertiseLink") +
            ":" +
            "%0A" +
            this.shareHref;
          let emailContactUrl =
            "mailto:" +
            this.user_email +
            "?subject=" +
            this.subject +
            "&body=" +
            body;
          window.location.href = emailContactUrl;
          break;
      }
    },

    // 复制微信
    copyWx() {
      console.log(1234);
      var clipboard = new this.Clipboard(".copy_btn");
      console.log(clipboard, "neir");
      clipboard.on("success", (e) => {
        this.$message.success(this.$t("copySuccess"));
        // 释放内存
        clipboard.destroy();
      });
    },

    // 收藏和取消收藏
    collectClick() {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t("other.noLoginPleaseToLogin"));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin",
          });
        }, 1500);
        return;
      }

      // 收藏状态
      let type = this.information.is_collection == 0 ? 1 : 2;
      this.$http
        .collectionInformation({
          information_id: this.information_id,
          type: type,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.getInformationDetail();
          } else {
            this.$message.info(res.msg);
          }
        });
    },

    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },

    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      if (
        fileType === ".jpg" ||
        fileType === ".png" ||
        fileType === ".jpeg" ||
        fileType === ".bmp" ||
        fileType === ".gif"
      ) {
      } else {
        this.$message.error(this.$t("pleaseUploadTheCorrectImageType"));
        return false;
      }
    },

    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },

    //提交举报
    toInformClick() {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t("other.noLoginPleaseToLogin"));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin",
          });
        }, 1500);
        return;
      }

      this.dialogVisible = true;
    },

    // 举报内容点击
    textareaClick() {
      this.$nextTick(() => {
        this.$refs.textareaRef.focus();
      });
    },

    // 提交举报
    btnClick() {
      if (this.isFlag) return;

      if (!this.content) {
        this.$message.error(this.$t("tips.theReportContentCannotBeEmpty"));
        return;
      }

      if (this.img_list.length == 0) {
        this.$message.error(this.$t("tips.photoCannotBeEmpty"));
        return;
      }

      this.isFlag = true;

      let thumb = this.img_list
        .map((item) => item.response.data[0].id)
        .join(",");
      console.log(thumb);

      let data = {
        content: this.content,
        information_id: this.information_id,
        thumb,
      };
      this.$http.inform(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.isFlag = false;
          this.dialogVisible = false;
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      });
    },

    // 删除
    deleteRelease(information_id) {
      console.log(information_id);
      this.$http
        .deleteInformation({
          information_id: information_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.dialogVisible_del = false;
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 编辑
    goEdit(id) {
      this.$router.push({
        path: "/release",
        query: {
          information_id: id,
          end_time: this.information.end_time,
        },
      });
    },

    // 仅自己可见
    isMySee(id) {
      console.log(id);
      this.$http
        .isStopMessage({
          id: id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.getInformationDetail();
          } else {
            this.$message.info(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.all_container {
  width: 100%;
  background: #f6f7f9;
  // padding-top: 150px;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    height: 86px;

    img {
      width: 14px;
      height: 14px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 12px;
    }

    .left {
      display: flex;
      justify-content: left;
      align-items: center;
      align-items: center;
    }

    .right_box {
      display: flex;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: right;

      .share_text {
        margin-right: 12px;
      }

      .share_list {
        display: flex;
        align-items: center;

        img {
          margin-right: 18px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;

      .right_box_one {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 142px;
        height: 20px;
        border-left: 1px solid #bfbfbf;
        box-sizing: border-box;

        img {
          margin-right: 12px;
        }
      }
    }
  }

  .video {
    position: relative;
    width: 100%;
    height: 720px;
    background-color: rgba(0, 0, 0, 0.5);

    .video_video {
      width: 100%;
      height: 100%;
    }

    .bofang {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 104px;
      height: 104px;
    }

    .video_title {
      position: absolute;
      bottom: 71px;
      left: 32px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: left;
    }

    .video_address {
      position: absolute;
      bottom: 24px;
      left: 32px;
      display: flex;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;

      .video_address_name {
        display: flex;
        align-items: center;
        margin-left: 32px;

        img {
          width: 22px;
          height: 22px;
          margin-right: 4px;
        }
      }
    }
  }

  .video_text {
    .top {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        justify-content: left;

        .left_box {
          min-width: 120px;
          padding: 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #bfbfbf;

          img {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }

          .name {
            text-align: left;
          }

          .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }

          .num {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .right {
        display: flex;
        justify-content: right;
        align-items: center;

        .right_box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 40px;

          img {
            width: 24px;
            height: 24px;
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 12px;
          }
        }
      }
    }

    .bottom {
      padding: 0 32px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: 1px solid #e0e0e0;
      .left {
        text-align: left;
        .title {
          font-weight: 600;
          font-size: 24px;
          color: #000;
          margin-bottom: 10px;
        }
        .money {
          font-size: 20px;
          color: red;
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .see {
          display: flex;
          justify-content: left;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.infoShow {
  position: relative;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px  50px 20px;
  font-size: 16px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  box-sizing: border-box;

  .info_box {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    // align-items: center;
    width: 12%;
    height: 80px;
    margin-top: 20px;
    border-right: 2px solid #000;
    box-sizing: border-box;


    // border: 2px solid #333;
    .title{
      width: 100%;
    }
    .name{
      width: 100%;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .border {
    position: absolute;
    top: 110px;
    left: 0px;
    width: 100%;
    border-top: 2px solid #000;
    box-sizing: border-box;
  }

  .info_box:nth-child(8n){
    border-right:none;

  }

  .name {
    // margin-top: 10px;
    word-wrap: break-word;
  }
}

.photoShow {
  width: 100%;
  background: #f6f7f9;
  box-sizing: border-box;
  text-align: left;

  .title {
    padding: 64px 32px 40px 32px;

    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }

  .photo_box {
    // width: 100%;
    // background: #fff;
    // display: grid;

    // padding-left: 20px;

    // box-sizing: border-box;
    // grid-gap: 32px;
    // width: 100%;

    // grid-template-columns: repeat(4, 440px);
    // grid-template-areas:
    //     "a  b  c  d "
    //     "e  b  c  f  "
    //     "e  g  h  f"
    //     "i  g  l  m "
    //     "i  k  l  m ";

    column-count: 4;
    column-gap: 10px;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    .box_item1 {
      width: 100%;
      height: 100%;
      grid-area: a;
    }

    .box_item2 {
      width: 100%;
      height: 100%;
      grid-area: b;
    }

    .box_item3 {
      width: 100%;
      height: 100%;
      grid-area: c;
    }

    .box_item4 {
      width: 100%;
      height: 100%;
      grid-area: d;
    }

    .box_item5 {
      width: 100%;
      height: 100%;
      grid-area: e;
    }

    .box_item6 {
      width: 100%;
      height: 100%;
      grid-area: g;
    }

    .box_item7 {
      width: 100%;
      height: 100%;
      grid-area: h;
    }

    .box_item8 {
      width: 100%;
      height: 100%;
      grid-area: f;
    }

    .box_item9 {
      width: 100%;
      height: 100%;
      grid-area: i;
    }

    .box_item10 {
      width: 100%;
      height: 100%;
      grid-area: k;
    }

    .box_item11 {
      width: 100%;
      height: 100%;
      grid-area: l;
    }

    .box_item12 {
      width: 100%;
      height: 100%;
      grid-area: m;
    }
  }
}

.btn {
  width: 220px;
  height: 48px;
  background: rgba(255, 255, 255);
  border: 1px solid #f39800;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 38px;
  color: #f39800;
  margin: 56px 0 64px 0;
}

.qr_code {
  display: inline-block;
  text-align: center;

  .scan_text {
    margin-top: -5px;
    font-size: 12px;
  }
}

/deep/.el-dialog__header {
  padding-left: 32px;
  text-align: left;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #dddddd;
}

.lianxi_dialog {
  border-radius: 8px;

  .wx {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .wx_num {
    margin-top: 20px;
    padding-bottom: 40px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #f39800;
    border-bottom: 1px solid #dddddd;
    box-sizing: border-box;
  }

  .copy_btn {
    margin-top: 24px;
    width: 300px;
    height: 40px;
    background: rgba(243, 152, 0);
    border-radius: 4px;
    border: none;
    color: #fff;
  }
}

.dialog_content {
  .inform_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
  }

  .textarea {
    margin-bottom: 16px;
  }

  .el-upload__tip {
    margin-left: 55px;
  }

  .btn {
    text-align: center;
    margin: 24px auto 16px;
    width: 300px;
    height: 40px;
    background: rgba(243, 152, 0);
    border-radius: 4px;
    color: #fff;
  }

  .tip {
    text-align: center;
  }
}

.info {
  width: 100%;
  padding: 32px 32px;
  box-sizing: border-box;

  .info_box {
    height: 280px;
    background: #fff;
    border: 1px solid #ecdfcc;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.4%;
    grid-template-rows: 33.3% 33.3% 33.3%;
  }

  .table {
    border: 1px solid #ecdfcc;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    text-align: left;
    padding-left: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      color: #8d5710;
      margin-bottom: 8px;
    }

    .name {
      color: #333333;
    }
  }
}

.release {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 56px;

  .release_box {
    width: 16%;
    min-height: 390px;
    background: #fff;
    text-align: left;
    margin-bottom: 10px;

    .release_box_top {
      height: 260px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .release_box_bootom {
      height: 110px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: left;
      }

      .address {
        display: block;

        .address_left {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #f39800;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .address_right {
          display: flex;
          // justify-content: space-between;
          justify-content: left;
          align-items: center;
          word-wrap: break-word;

          .img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.checkStatus {
  width: 100%;
  height: 178px;
  background: #f6f7f9;
  padding: 32px 620px 0;
  box-sizing: border-box;

  .status {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 40px;

    .lose {
      color: #e60012;
    }

    .win {
      color: #6ab536;
    }

    .ing {
      color: #2d91ff;
    }
  }

  .btn_box {
    display: flex;
    justify-content: space-between;

    .del_btn {
      width: 300px;
      height: 48px;
      background: #fff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      color: #333;
      font-size: 16px;
    }

    .release_btn {
      width: 300px;
      height: 48px;
      background: rgba(243, 152, 0);
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
    }
  }
}

.checkStatusColorLose {
  background-color: rgba(230, 0, 18, 0.2) !important;
}

.checkStatusColorWin {
  background: rgba(106, 181, 54, 0.2) !important;
}

.checkStatusColoring {
  background: rgba(45, 145, 255, 0.2);
  height: 88px;
}

/deep/ .el-dialog {
  // width: 490px;
  // height: 280px;
  border-radius: 8px;
}

/deep/ .el-dialog__header {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 32px !important;
  height: 70px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

/deep/ .el-button--primary {
  width: 200px;
  height: 40px;
}

/deep/ .el-button {
  width: 200px;
  height: 40px;
}

/deep/.el-dialog__footer {
  // padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

// /deep/.el-dialog__body {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-top: 1px solid #ccc;
//     border-bottom: 1px solid #ccc;
//     font-size: 20px;
//     padding: 0 !important;
//     height: 130px;

// }
</style>